"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    directives: [{
      name: "resizeobserver",
      rawName: "v-resizeobserver",
      value: _vm.onListResize,
      expression: "onListResize"
    }],
    key: 'messagelist-' + _vm.buffer.name,
    ref: "scroller",
    staticClass: "kiwi-messagelist",
    class: {
      'kiwi-messagelist--smoothscroll': _vm.smooth_scroll,
      'kiwi-messagelist--showtyping': _vm.buffer.setting('share_typing')
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.onListClick.apply(null, arguments);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "resizeobserver",
      rawName: "v-resizeobserver",
      value: _vm.onListResize,
      expression: "onListResize"
    }]
  }, [_vm.shouldShowChathistoryTools ? _c('div', {
    staticClass: "kiwi-messagelist-scrollback"
  }, [!_vm.buffer.flag('is_requesting_chathistory') ? _c('a', {
    staticClass: "u-link",
    on: {
      "click": function click($event) {
        return _vm.buffer.requestScrollback();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('messages_load')) + " ")]) : _c('a', [_vm._v(_vm._s(_vm.$t('messages_loading')))])]) : _vm._e(), _c('remove-before-update', [_vm._l(_vm.filteredMessagesGroupedDay, function (day) {
    return [_vm.filteredMessagesGroupedDay.length > 1 && day.messages.length > 0 ? _c('div', {
      key: 'msgdatemarker' + day.dayNum,
      staticClass: "kiwi-messagelist-seperator"
    }, [_c('span', [_vm._v(_vm._s(new Date(day.messages[0].time).toDateString()))])]) : _vm._e(), _c('remove-before-update', {
      key: day.dayNum
    }, [_vm._l(day.messages, function (message) {
      return [_vm.shouldShowUnreadMarker(message) ? _c('div', {
        key: 'msgunreadmarker' + message.id,
        staticClass: "kiwi-messagelist-seperator"
      }, [_c('span', [_vm._v(_vm._s(_vm.$t('unread_messages')))])]) : _vm._e(), _c('div', {
        key: 'msg' + message.id,
        class: ['kiwi-messagelist-item', _vm.selectedMessages[message.id] ? 'kiwi-messagelist-item--selected' : '']
      }, [message.render() && message.template && message.template.$el && _vm.isTemplateVue(message.template) ? _c('div', {
        directives: [{
          name: "rawElement",
          rawName: "v-rawElement",
          value: message.template.$el,
          expression: "message.template.$el"
        }]
      }) : message.render() && message.template ? _c(message.template, _vm._b({
        tag: "component",
        attrs: {
          "buffer": _vm.buffer,
          "message": message,
          "idx": _vm.filteredMessages.indexOf(message),
          "ml": _vm.thisMl
        }
      }, 'component', message.templateProps, false)) : _vm.listType === 'modern' ? _c('message-list-message-modern', {
        attrs: {
          "message": message,
          "idx": _vm.filteredMessages.indexOf(message),
          "ml": _vm.thisMl
        }
      }) : _vm.listType === 'inline' ? _c('message-list-message-inline', {
        attrs: {
          "message": message,
          "idx": _vm.filteredMessages.indexOf(message),
          "ml": _vm.thisMl
        }
      }) : _vm.listType === 'compact' ? _c('message-list-message-compact', {
        attrs: {
          "message": message,
          "idx": _vm.filteredMessages.indexOf(message),
          "ml": _vm.thisMl
        }
      }) : _vm._e()], 1)];
    })], 2)];
  })], 2), _c('transition', {
    attrs: {
      "name": "kiwi-messagelist-joinloadertrans"
    }
  }, [_vm.shouldShowJoiningLoader ? _c('div', {
    staticClass: "kiwi-messagelist-joinloader"
  }, [_c('LoadingAnimation')], 1) : _vm._e()]), _vm.shouldRequestChannelKey ? _c('buffer-key', {
    attrs: {
      "buffer": _vm.buffer,
      "network": _vm.buffer.getNetwork()
    }
  }) : _vm._e()], 1), !_vm.auto_scroll ? _c('button', {
    staticClass: "scroll-to-bottom",
    on: {
      "click": _vm.scrollToBottom
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]) : _vm._e()]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;